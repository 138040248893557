// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  ignoreErrors: [
      'fbq is not defined',
      "'fbq' is undefined",
      "Can't find variable: fbq",
      "Can't find variable: Components",
      "Components is not defined",
      "Can't find variable: ReactDOM",
      "ReactDOM is not defined",
      "'ReactDOM' is not defined",
      "ReferenceError: Can't find variable: ReactDOM",
      "Can't find variable: google_tag_manager",
      "Failed to fetch",
      "Non-Error promise rejection captured with value: Object Not Found Matching"
  ]
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
