import { useEffect } from 'react';
import * as Sentry from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations';
import getConfig from 'next/config';
import '../styles/index.scss';

// Polyfills
if (typeof window !== 'undefined') {
    require("ie11-custom-properties");
}

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const config = getConfig();
    const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
    Sentry.init({
        enabled: process.env.NODE_ENV === 'production',
        integrations: [
            new RewriteFrames({
                iteratee: (frame) => {
                    frame.filename = frame.filename.replace(
                        distDir,
                        'app:///_next'
                    );
                    return frame;
                },
            }),
        ],
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
        allowUrls: [
            /https?:\/\/((stage|www)\.)?nordr\.se/,
            /https?:\/\/((stage|www)\.)?folkhem\.se/,
        ]
    });
}

function MyApp({ Component, pageProps, err }) {
    useEffect(() => {
        const classList = document.querySelector('body').classList;
        classList.remove('preload');
    }, []);

    return <Component {...pageProps} err={err} />;
}

export default MyApp;
